import React from 'react'
import Layout from 'gatsby-theme-tailwindui/src/components/layout'
import { useSiteMetadata } from 'gatsby-theme-tailwindui/src/utils/use-site-metadata'
import QuoteComponent from '../components/quotes/QuoteComponent'
import QuotePageLayout from '../components/quotes/QuotePageLayout'

const AllQuotes = (props) => {
  const { quotes } = props.pageContext
  const { data, errors } = props
  const page = data && data.page
  const { globalNavMenu, globalContactMenu } = useSiteMetadata()
  const menuItems = globalNavMenu
  const contactMenu = globalContactMenu

  return (
    <Layout navMenuItems={menuItems} contactMenuItems={contactMenu}>
      <QuotePageLayout>
        {quotes.map((quote, i) => (
          <QuoteComponent key={i} data={quote} />
        ))}
      </QuotePageLayout>
    </Layout>
  )
}

export default AllQuotes
